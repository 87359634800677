<template>
  <svg
    fill="none"
    height="16"
    width="17"
    xmlns="http://www.w3.org/2000/svg"
  ><path
    d="M15.3547.98011c-.3887 0-.693.32074-.693.69299v1.65589l-.9965-.99656C12.1446.828337 10.1343 0 8.00516 0c-2.1285 0-4.15596.82827-5.66 2.34882C.723576 3.95389-.121251 6.20049.014082 8.49845c.0171591.37157.337911.65866.692987.65866h.050818c.388733-.01716.675823-.35508.658653-.74314-.11813-1.89213.59135-3.73425 1.92576-5.06866C5.82577.828219 10.1678.828219 12.6685 3.32816l.9965.99656h-1.6559c-.3887 0-.693.32075-.693.69299 0 .37157.3208.69299.693.69299h3.3455c.3887 0 .693-.32075.693-.69299V1.6729c0-.38873-.3207-.692986-.693-.692986l.0001.000196Z"
    fill="currentColor"
  /><path
    d="m10.5727 8.43137-1.90938-.87843V4.32556c0-.38874-.32075-.69299-.69299-.69299-.37157 0-.69299.32074-.69299.69299L7.278 7.99172c0 .2706.1518.52403.40523.64216L10.015 9.69844c.1016.05081.1861.06731.2871.06731.2706 0 .524-.15179.6421-.40522.1353-.35507-.0171-.77737-.3715-.92916ZM5.90808 14.2603c-.42239-.1353-.82761-.3207-1.21634-.5405-.33791-.1861-.7603-.0845-.96291.2534-.18613.3379-.08448.7603.25343.9629.45604.2706.96291.4897 1.46977.6587.06732.0171.1518.0337.21978.0337.28709 0 .57418-.1862.65866-.4733.15179-.3715-.05082-.7602-.42239-.8949ZM9.71565 14.3785c-.42238.1182-.86194.1861-1.31798.2198-.38873.0172-.67582.3551-.65866.7431.01716.3716.33791.6587.69299.6587h.05082c.54052-.0337 1.06455-.1181 1.58788-.2534.3716-.1017.5914-.4897.4897-.862-.0845-.3881-.4732-.6078-.84475-.5062ZM2.09167 10.9658c-.16895-.338-.59134-.4897-.94642-.3043-.355073.1861-.489696.5913-.304251.9464.236271.4732.524031.9293.861941 1.3517.1353.1689.33791.2706.55769.2706.1518 0 .30425-.0509.43955-.1518.30426-.2363.35508-.6759.11814-.9801-.28709-.3722-.52338-.7445-.72665-1.1325ZM15.321 8.31233c-.3716-.06732-.7431.21977-.7939.59134-.0674.43955-.169.87843-.3208 1.28433-.1353.3716.0673.7603.4224.8956.0845.0337.1518.0337.2363.0337.2871 0 .5577-.1862.6586-.4733.1861-.5068.3043-1.03085.3888-1.55421.0679-.35507-.2027-.70948-.5914-.77746ZM12.9528 12.3674c-.0845.1016-.1861.2026-.2871.3043-.2198.2197-.456.4223-.693.6085-.3042.2362-.3715.6758-.1353.98.1353.1862.3551.2706.5577.2706.1518 0 .2871-.0508.4224-.1353.2871-.2197.5742-.456.8448-.7266.1181-.1181.2362-.2363.3379-.3551.2534-.2871.2363-.7266-.0673-.9801-.2871-.287-.7267-.2534-.9801.0337Z"
    fill="currentColor"
  /></svg>
</template>
